<template>
    <div class="editModulModal-mian-box">
        <el-dialog
        :title="$t('PV.editManufacturer')"
        :visible="editVisible"
        width="900px"
        @open="tmanufacturerGetPage"
        @close="closeEditVisible"
        :close-on-click-modal='false'
        >   
            <div style="overflow: hidden;">
                <div class="search-box">
                    <div class="search-box-sub">
                        <span class="lable-text">{{$t('PV.manufacturer')}}</span>
                        <el-input v-model="name" style="width:160px;" :placeholder="$t('common.info.pl-insert-content')"></el-input>
                    </div>
                    <div class="search-box-sub">
                        <el-button type="primary" @click='query'>{{$t('common.button.query')}}</el-button>
                    </div>
                </div>
                <div class="button-box">
                    <el-popconfirm
                            :title="$t('common.button.del-bath-confirm')"
                            @confirm='systemModuleDeleteBatch()'
                    >
                        <el-button slot="reference" icon='el-icon-delete' class="marginR10" >{{$t('link.delete')}}</el-button>
                    </el-popconfirm>
                </div>
                <baseTable v-loading="loading"  row-key='sid' :data="dataRows" border  stripe   :column='column' :select='true' @selection-change="handleSelectionChange($event)" >
                    <template #manufacturer='{data}'>
                        <div class="el-input-ccc">
                            <el-input v-model="data.name" @change='saveName(data)' style="width:230px;"></el-input>
                        </div>
                    </template>
                    <template #visible='{data}'>
                        <span v-if="data.visible == 1">All</span> 
                        <span v-else>{{data.email}}</span> 
                    </template>
                    <template #option="{data}">
                        <div class="option-box">
                            <template v-if='userType == 16'>
                                <i class="iconfont icon-visible marginR10" :title="$t('Private')" v-if='data.visible == 1' @click="setPublic(data,0)" style="color:#fea429"></i>
                                <i class="iconfont icon-invisible marginR10" :title="$t('Public')" v-else @click="setPublic(data,1)"></i>
                            </template>
                            <el-popconfirm
                                    :title="$t('common.button.del-bath-confirm')" @confirm='systemModuleDelete(data.manufacturerID)'
                                    >
                                <i class="el-icon-delete" slot="reference"></i>
                            </el-popconfirm>
                        </div>
                    </template>
                </baseTable>
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="current"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
                </el-pagination>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="$emit('update:editVisible', false)">{{$t('system.button.cancel')}}</el-button> -->
                <el-button  type="primary" @click="toAdminModule">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
export default {
    components: {
        baseTable
    },
    props: {
        editVisible:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            dataRows:[{}],
            column:[{
                title: this.$t('PV.manufacturer'),
                value: 'name',
                slot: 'manufacturer'
            },{
                title: this.$t('Visible_range'),
                value: 'visible',
                slot: 'visible',
                show: sessionStorage.getItem('userType') == 16
            },{
                title: this.$t('common.msg.opeation'),
                value: 'option',
                slot: 'option'
            }],
            size:10,
            current:1,
            total:1,
            loading:false,
            name:'',
            manufacturerIDS:[],
            userType:sessionStorage.getItem('userType'),
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        // this.tmanufacturerGetPage()
    },
    methods: {
        async setPublic(data,publicFlag){
            let res = await this.API.tmanufacturerUpdate({
                visible: publicFlag,
                manufacturerID: data.manufacturerID
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.tmanufacturerGetPage()
            }else {
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        async systemModuleDeleteBatch(){
            let res = await this.API.tmanufacturerDeleteBatch({
                manufacturerIDs:this.manufacturerIDS
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.tmanufacturerGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        async systemModuleDelete(id){
            let res = await this.API.tmanufacturerDeleteBatch({
                manufacturerIDs:id
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.tmanufacturerGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        async saveName(data){
            let res = await this.API.saveName({
                manufacturerID:data.manufacturerID,
                name:data.name
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.tmanufacturerGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
                this.tmanufacturerGetPage()
            }
        },
        // async tmanufacturerGetPage(){
        //     this.loading = true
        //     let res = await this.API.tmanufacturerGetPage({
        //         name:this.name,
        //         current:this.current,
        //         size:this.size,
        //     })
        //     this.loading = false
        //     this.dataRows = res.rows
        //     this.total = parseInt(res.total||0)
        // },
        // 返回adminModule页面
        toAdminModule(){
            this.$emit('update:editVisible', false)
            this.$emit("systemTModuleGetPage")
        },
        async tmanufacturerGetPage(){
            this.loading = true
            let res = await this.API.tmanufacturerGetEditPage({
                name:this.name,
                current:this.current,
                size:this.size,
            })
            this.loading = false
            this.dataRows = res.rows
            this.total = parseInt(res.total||0)
        },
        query(){
            this.current = 1
            this.tmanufacturerGetPage()
        },
        handleSelectionChange(val){
          this.manufacturerIDS=val.map( item=>{
            return item.manufacturerID
          })
        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.tmanufacturerGetPage()
        },
        handleCurrentChange(val) {
            this.current = val
            this.tmanufacturerGetPage()
        },
        closeEditVisible(){
            this.$emit('update:editVisible', false);
            this.$emit("systemTModuleGetPage")
        }
    },
};
</script>

<style  lang="less" >
.editModulModal-mian-box{
   .el-input-ccc .el-input--small .el-input__inner{
        background-color: #eee;
        height: 23px;
    }
}
</style>
