<!--
 * @Author: your name
 * @Date: 2020-11-24 12:17:18
 * @LastEditTime: 2021-05-10 09:55:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\adminInverter.vue
-->
<template>
    <div class="adminInverter-main-box">
        <div class="search-box">
            <div class="search-box-sub">
                <span class="lable-text">{{$t('new.PV.management.manufacturer')}}</span>
                <el-select v-model="manufacturerID" filterable :placeholder="$t('common.msg.select.please')" clearable>
                    <el-option
                    v-for="item in selectDataList"
                    :key="item.manufacturerID"
                    :label="item.name"
                    :value="item.manufacturerID">
                    </el-option>
                </el-select>
            </div>
            <div class="search-box-sub">
                <span class="lable-text">{{$t('project.module.select-name')}}</span>
                <el-input v-model="name" style="width:160px;" :placeholder="$t('common.info.pl-insert-content')"></el-input>
            </div>
            <div class="search-box-sub">
                <el-button type="primary" @click='query'>{{$t('common.button.query')}}</el-button>
            </div>
        </div>
        <div class="button-box">
            <el-button type="primary" class="" icon='el-icon-plus' @click="()=>{$router.push({path:'/createModule'})}">{{$t('PV.modules.new')}}</el-button>
            <el-button type="primary" class="marginR10" icon='el-icon-edit' @click="editVisible = true">{{$t('PV.editManufacturer')}}</el-button>
            <el-button type="primary" icon='el-icon-upload2' class="pull-right" @click="dialogVisible = true">{{$t('system.import')}}</el-button>
            <el-popconfirm
                    :title="$t('common.button.del-bath-confirm')"
                    @confirm='systemModuleDeleteBatch()'
            >
                <el-button slot="reference" icon='el-icon-delete' class="marginR10" >{{$t('link.delete')}}</el-button>
            </el-popconfirm>
        </div>
        <baseTable v-loading="loading"  row-key='sid' :data="dataRows" border  stripe   :column='column' :select='true' @selection-change="handleSelectionChange($event)" >
            <template #math="{data}">
                {{data.moduleLength}}*{{data.moduleWidth}}
            </template>
            <template #option="{data}">
                <div class="option-box">
                    <template v-if='userType == 16'>
                        <i class="iconfont icon-visible marginR10" :title="$t('Private')" v-if='data.visible == 1' @click="setPublic(data,0)" style="color:#fea429"></i>
                        <i class="iconfont icon-invisible marginR10" :title="$t('Public')" v-else @click="setPublic(data,1)"></i>
                    </template>
                    <el-popconfirm
                            :title="$t('common.button.del-bath-confirm')" @confirm='systemModuleDelete(data.moduleID)'>
                        <i class="el-icon-delete" slot="reference"></i>
                    </el-popconfirm>
                </div>
            </template>
            <template #name='{data}'>
                <a href="javascript:;" style="color: #4c98d9;" @click='pushEditUrl(data.name,data.moduleID)'>{{data.name}}</a>
            </template>
            <template #visible='{data}'>
                <span v-if="data.visible == 1">All</span> 
                <span v-else>{{data.email}}</span> 
            </template>
        </baseTable>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <!--导入弹出框-->
        <el-dialog
                :title="$t('project.module.import')"
                :visible.sync="dialogVisible"
                width="30%"
                v-if='dialogVisible'
                >
            <el-button type="text" @click="download">
                {{$t('system.template.upload')}}
            </el-button>
            <base-file v-model="uploadFile" ></base-file>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('system.button.cancel')}}</el-button>
                <el-button :loading="loadingBut" type="primary" @click="submitFile">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>
        <editModal :editVisible.sync='editVisible' @systemTModuleGetPage="closeEditVisible"></editModal>
    </div>
</template>

<script>
import editModal from './editModulModal'
import baseTable from '../../components/common/baseTable'
import baseFile from '../../components/common/baseFileInputNew'
export default {
    components: {
        baseTable,
        baseFile,
        editModal
    },
    props: {

    },
    data() {
        return {
            editVisible:false,
            uploadFile: '',
            fileList:[],
            action:'',
            manufacturerID:'',
            materialName: '',
            name:'',
            dialogVisible: false,
            selectDataList:[],
            moduleIDs:[],
            loading:false,
            loadingBut:false,
            dataRows:[{},{}],
            column:[{
                title: this.$t('PV.manufacturer'),
                value: 'manufacturerName',
            },{
                title: this.$t('project.module.select-name'),
                value: 'name',
                slot: 'name'
            },{
                title: this.$t('PV.wafer.process'),
                value: 'materialName',
            },{
                title: this.$t('PV.rated.power')+ '(W)',
                value: 'pModMPP',
            },{
                title: this.$t('PV.module.area')+ '(mm²)',
                value: 'math',
                slot: 'math'
            }
            ,{
                title: this.$t('import.time'),
                value: 'importTime',
                slot: 'importTime'
            },{
                title: this.$t('Visible_range'),
                value: 'visible',
                slot: 'visible',
                show: sessionStorage.getItem('userType') == 16
            },{
                title: this.$t('common.msg.opeation'),
                value: 'option',
                slot: 'option'
            }],
            size:10,
            current:1,
            total:1,
            userType:sessionStorage.getItem('userType'),
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.systemTModuleGetPage()
        this.systemTManufacturerGetList()
    },

    methods: {
        async setPublic(data,publicFlag){
            let res = await this.API.tmoduleUpdateVisible({
                visible: publicFlag,
                moduleID: data.moduleID
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.systemTModuleGetPage()
            }else {
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        /*提交文件*/
        async submitFile() {
            console.log(this.uploadFile,"this.uploadFile");
            if (!this.uploadFile) {
                this.$message({
                    message: this.$t('remind.upload-file-first'),
                    type: 'warning'
                });
                return
            }
            this.loadingBut = true
            let res = await this.API.systemModuleImport({
                file: this.uploadFile
            })
            this.loadingBut = false
            console.log(res)
            if(res.success){
                if(res.object.length>0){
                    this.$message({
                        message: this.$t('Duplicate_items_msg')+`${JSON.stringify(res.object)}`,
                        type: 'warning'
                    });
                }else{
                    this.$message({
                        message: this.$t('operate.result.success'),
                        type: 'success'
                    });
                }
                this.dialogVisible = false
                this.uploadFile = ''
                this.systemTModuleGetPage()
            }else {
                if (res.code == 500) {
                    this.$message({
                        message: res.msg,
                        type: 'warning'
                    });
                    return
                }
                res.code = res.code || 1
                let errortip = 'import.error.tip' + res.code
                this.$message({
                    message: this.$t(errortip),
                    type: 'warning'
                });
            }
        },

        /*
        * 列表分页查询
        * */
        async systemTModuleGetPage(){
            this.loading = true
            let res = await this.API.systemTModuleGetPage({
                manufacturerID: this.manufacturerID,
                name: this.name,
                current:this.current,
                size:this.size,
            })
            this.dataRows = res.rows
            this.total= parseInt(res.total)
            this.loading = false

        },

        download(){
            window.open(window.location.href.split('#')[0] + 'static/module.xls')
        },

        /*跳转详情页面*/
        pushEditUrl(name,id){
            this.$router.push({path: '/createModule', query: {id:id}});
        },

        /*
        * 获取所有制造商数据
        * */
        async systemTManufacturerGetList(){
            let res = await this.API.systemTManufacturerGetList()
            this.selectDataList = res
        },

        query(){
            this.current = 1
            this.systemTModuleGetPage()
        },

        /*批量删除*/
        async systemModuleDeleteBatch(){
            let res = await this.API.systemModuleDeleteBatch({
                moduleIDs:this.moduleIDs
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.systemTModuleGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },

        /*删除*/
        async systemModuleDelete(id){
            let res = await this.API.systemModuleDelete({
                moduleID:id
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.systemTModuleGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },


        edit(id){
            console.log(id)
        },
        handleSelectionChange(val){
          this.moduleIDs=val.map( item=>{
            return item.moduleID
          })
        },
        handleSizeChange(val) {
            this.size = val
            this.current = 1
            this.systemTModuleGetPage()
        },
        handleCurrentChange(val) {
            this.current = val
            this.systemTModuleGetPage()
        },
        closeEditVisible(){
            this.systemTModuleGetPage()
            this.systemTManufacturerGetList()
        }
    },
};
</script>

<style  lang="less" scoped>
.adminInverter-main-box{
    
}
</style>
